import { replaceQueryParam } from '@root/javascript/util/url-utils';
import { application, Controller } from '@local-stimulus';
import CookieUtils from '@utils/cookie-utils';
class LayoutOptionController extends Controller {
    save() {
        const newValue = this.selectTarget.value;
        const cookieKey = this.data.get('cookie-key');
        const key = this.data.get('key') || 'layout';
        if (cookieKey) {
            CookieUtils.set(cookieKey, newValue);
        }
        window.location.href = replaceQueryParam(window.location.href, key, newValue);
    }
}
LayoutOptionController.targets = ['select'];
application.register('layout-option', LayoutOptionController);
export default LayoutOptionController;
