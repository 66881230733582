import { replaceQueryParam } from '@root/javascript/util/url-utils';
import { application, Controller } from '@local-stimulus';
class SortingOptionController extends Controller {
    save() {
        const newValue = this.selectTarget.value;
        const key = this.data.get('key') || 'sortby';
        if (this.data.get('global') === 'true') {
            window.Viewer.preferences.set(key, newValue);
        }
        window.location.href = replaceQueryParam(window.location.href, key, newValue);
    }
}
SortingOptionController.targets = ['select'];
application.register('sorting-option', SortingOptionController);
export default SortingOptionController;
