const trackRelatedLinkClick = function (linkId, href) {
    const { gtag } = window;
    if (typeof gtag !== 'undefined') {
        gtag('event', linkId, {
            event_category: 'related link',
            event_label: href,
        });
    }
};
export default trackRelatedLinkClick;
export { trackRelatedLinkClick };
