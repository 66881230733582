var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _instances, __element, __controller, _controller_get, _element_get, _a;
import { application } from '@local-stimulus';
export default new (_a = class {
        constructor() {
            _instances.add(this);
            __element.set(this, void 0);
            __controller.set(this, void 0);
        }
        show(scope) {
            __classPrivateFieldGet(this, _instances, "a", _element_get).dataset.markFilterModalScope = scope;
            __classPrivateFieldGet(this, _instances, "a", _controller_get).open();
        }
    },
    __element = new WeakMap(),
    __controller = new WeakMap(),
    _instances = new WeakSet(),
    _controller_get = function _controller_get() {
        if (!__classPrivateFieldGet(this, __controller, "f")) {
            __classPrivateFieldSet(this, __controller, application.getControllerForElementAndIdentifier(__classPrivateFieldGet(this, _instances, "a", _element_get), 'mark-filter-modal'), "f");
        }
        return __classPrivateFieldGet(this, __controller, "f");
    },
    _element_get = function _element_get() {
        if (!__classPrivateFieldGet(this, __element, "f")) {
            __classPrivateFieldSet(this, __element, document.querySelector('[data-controller="mark-filter-modal"]'), "f");
        }
        return __classPrivateFieldGet(this, __element, "f");
    },
    _a)();
