import { replaceQueryParam } from '@root/javascript/util/url-utils';
import { application, Controller } from '@local-stimulus';
const KEY = 'ongoing';
class OngoingFilterOptionController extends Controller {
    save() {
        const newValue = this.selectTarget.value;
        window.Viewer.preferences.set(KEY, newValue);
        window.location.href = replaceQueryParam(window.location.href, KEY, newValue);
    }
}
OngoingFilterOptionController.targets = ['select'];
application.register('ongoing-filter-option', OngoingFilterOptionController);
export default OngoingFilterOptionController;
